<template>
  <base-layout :loadingFlag="$store.state.loading">
    <breadcrumbs :links="links" />
    <div class="block">
      <p class="content spinner-wrapper" v-if="$store.state.loading">
        <span class="icon is-large has-text-info spinner">
          <font-awesome-icon icon="spinner" size="lg" spin />
        </span>
      </p>
      <rege-form
        v-else
        :regeAcknowledgeLine="$store.state.rege.regeAcknowledgeLine"
        :rege="$store.state.rege.rege"
        action="next"
        :option="$store.state.arr.paymentInfo.paymentOption"
        @rege-accepted="setRegEAccepted"
      />
    </div>
    <div class="buttons">
      <button
        class="button is-success"
        @click="recordRegE"
        :disabled="!regEAccepted"
      >
        Continue
      </button>
      <button class="button is-danger" @click="deletePendingPPA">Cancel</button>
    </div>
  </base-layout>
</template>
<script>
import {mapState, mapActions, mapGetters} from "vuex";
import BaseLayout from "@/components/BaseLayout";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RegEForm from "@/components/rege/RegEForm.vue";
import store from "@/store/index";

function unloadListener(event) {
  event.preventDefault();
  event.returnValue = "";
}

export default {
  computed: {
    ...mapState(["instanceConfig"]),
    ...mapGetters(["settlementAcceptanceLabel"])
  },
  components: {
    "base-layout": BaseLayout,
    breadcrumbs: Breadcrumbs,
    "rege-form": RegEForm
  },
  data() {
    return {
      links: [],
      regEAccepted: false
    };
  },
  created() {
    this.links = [
      { label: "Home", icon: "home", to: { name: "home" } },
      {
        label: "Make Payment",
        icon: "dollar-sign",
        to: { name: "makePayment", params: { id: this.$route.params.id } }
      },
      {
        label: this.instanceConfig.overTimeTitle,
        icon: "calendar",
        to: { name: "overTime", params: { id: this.$route.params.id } }
      },
      {
        label: this.settlementAcceptanceLabel,
        icon: "check",
        to: {
          name: "amortizationSchedule",
          params: { id: this.$route.params.id }
        }
      },
      {
        label: "Payment",
        icon: null,
        to: { name: "overTime.payment", params: { id: this.$route.params.id } }
      },
      {
        label: "RegE",
        icon: null,
        to: { name: "overTime.regE", params: { id: this.$route.params.id } },
        isActive: true
      }
    ];
  },
  beforeRouteEnter(to, from, next) {
    if (
      store.state.instanceConfig &&
      Object.keys(store.state.instanceConfig).length === 0 &&
      store.state.instanceConfig.constructor === Object
    ) {
      next({
        name: "overTime",
        params: { instanceId: to.params.instanceId, id: to.params.id }
      });
    } else {
      window.addEventListener("beforeunload", unloadListener);
      next(async vm => {
        vm.$store.commit("SET_LOADING", true);
        try {
          await vm.fetchRegEConfig();
          await vm.fetchRegEMessages({
            option: vm.$store.state.arr.paymentInfo.paymentOption,
            action: "next",
            relatedAccount: vm.$route.params.id
          });
        } catch (e) {
          vm.$store.commit("SET_GLOBAL_NOTIFICATION", {
            showNotification: true,
            notificationMessage: e,
            notificationType: "error"
          });
        } finally {
          vm.$store.commit("SET_LOADING", false);
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    next();
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"]),
    ...mapActions("rege", [
      "fetchRegEConfig",
      "fetchRegEMessages",
      "saveRegEAcceptance"
    ]),
    ...mapActions("arr", ["deleteArrangement"]),
    setRegEAccepted({ sdDisclosureLabel, sdDisclosureText }) {
      this.regEAccepted = true;
      this.$store.commit("rege/SET_SD_DISCLOSURE_LABEL", sdDisclosureLabel);
      this.$store.commit("rege/SET_SD_DISCLOSURE_TEXT", sdDisclosureText);
    },
    async recordRegE() {
      // enabled when flag set
      window.scrollTo(0, 0);
      this.$router.push({ name: "overTime.finalReview" });
    },
    async deletePendingPPA() {
      this.$store.commit("SET_LOADING", true);
      try {
        await this.deleteArrangement();
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: e,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }

      this.$router.push({ name: "overTime" });
    }
  }
};
</script>
